<template>
    <div>
        <div v-if="open" class="card shadow">
            <div role="list" class="p-3 chat-header d-flex align-items-center justify-content-between">
                <div class="text-white text-capitalize">{{ this.title }}</div>
                <a @click="toggle">
                    <i aria-hidden="true" class="text-white fa fa-times-circle"></i>
                </a>
            </div>
            <div class="container chat-background">
                <form name='contact-form' novalidate>
                    <slot></slot>
                    <div class="mt-3 form-group row">
                        <div class="col-6">
                            <label>Nombre</label>
                            <input class="form-control" type="text" name="name" id="name" required/>
                            <small class="d-none text-danger">El campo nombre es necesario'</small>
                        </div>
                        <div class="col-6">
                            <label>Email</label>
                            <input class="form-control" type="email" name="email" id="email" required/>
                            <small class="d-none text-danger">El campo email es necesario</small>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <label>Mensaje</label>
                            <textarea name="message" id="" class="form-control" rows="5" required></textarea>
                            <small class="d-none text-danger">El campo mensaje es necesario</small>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-12">
                            <button @click="submitform()" type="submit" class="px-5 text-white btn btn-primary">
                                <div class="loader-container position-absolute w-100 h-100 d-flex align-items-center justify-content-center">
                                    <div class="loader">
                                        Loading...
                                    </div>
                                </div>
                                Escribinos
                            </button>
                        </div>
                    </div>
                </form>
                <div id="error" class="alerta alert alert-danger" role="alert">
                    Existen errores en el formulario!!
                </div>
                <div id="success" class="alerta alert alert-success" role="alert">
                    Gracias por contactar con nostros
                </div>
            </div>
        </div>
        <a @click="toggle" class="btn btn-contact">
            <svg v-if="!open" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="4"></circle><path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path></svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
        </a>
    </div>
</template>

<script>
    import request from 'oc-request';
    export default {
        name: "contact",
        data(){
            return {
                open: false,
                fab: false,
                fav: true,
                menu: false,
                message: false,
                hints: true,
                text: "",
                apilink: ""
            }
        },
        props: {
            phone:{
                type: String,
                required: false,
                default: '39567814'
            },
            title: {
                type: String,
                required: false,
            }
        },
        watch: {
            text(val) {
                this.apilink = 'http://';
                this.apilink += this.isMobile() ? 'api' : 'web';
                this.apilink += '.whatsapp.com/send?phone=' + this.phone + '&text=' + encodeURI(this.text);
            }
        },
        mounted: function () {},
        methods: {
            isMobile() {
                var check = false;
                (function (a) {
                    if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true;
                })(navigator.userAgent || navigator.vendor || window.opera);
                return check;
            },
            toggle() {
                this.open = !this.open;
            },
            toJson(value) {
                return JSON.parse(value);
            },
            submitform() {
                let form = document.querySelector(`form[name='contact-form']`);
                form.addEventListener('submit', (e) => {
                        form.classList.add('was-validated');
                        if(form.checkValidity() === false){
                            e.preventDefault();
                            e.stopPropagation();
                            this.showErrors(form);
                            this.alertHandler('#errors');
                        }else{
                            e.preventDefault();
                            e.stopPropagation();
                            this.loaderHandler(form, '.loader-container');
                            request.sendForm(form, 'contacto::onFormSubmit', {
                                flash: true,
                                success: () => {
                                    this.alertHandler('#success');
                                    this.loaderHandler(form, '.loader-container');
                                    this.resetForm(form);
                                }
                            })
                        }

                    })
            },
            showErrors(form) {
                let errorMessages = form.querySelectorAll('small.text-danger');
                errorMessages.forEach((el) => {
                    el.classList.remove('d-none');
                })
            },
            hideErrors(form) {
                let errorMessages = form.querySelectorAll('small.text-danger');
                errorMessages.forEach((el) => {
                    el.classList.add('d-none');
                })
            },
            alertHandler(selector) {
                setTimeout(() => {
                    let alert = document.querySelector(`${selector}`);
                    alert.classList.add('show');
                    setTimeout(() => {
                        alert.classList.remove('show');
                    }, 5000)
                }, 300)
            },
            loaderHandler(form, selector) {
                let loader = form.querySelector(`${selector}`);
                if(loader.classList.contains('is-active')){
                    loader.classList.remove('is-active');
                }else{
                    loader.classList.add('is-active');
                }
            },
            resetForm(form) {
                form.classList.remove('was-validated');
                let fields = form.querySelectorAll('input[name], textarea[name]');

                fields.forEach((el) => {
                    el.value = '';
                })
            }
        },
    }
</script>

<style scoped lang="scss">
    .btn-contact {
        position: fixed;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 40px;
        right: 40px;
        background-color: #fc8ca2;
        color: #fff;
        border-radius: 50px;
        text-align: center;
        font-size: 30px;
        box-shadow: 2px 2px 3px #999;
        z-index: 100;

        svg {
            stroke: #fff
        }
    }

    .card {
        min-width: 300px;
        position: fixed;
        bottom: 110px;
        right: 20px;

        @media screen and (max-width: 410px){
            right: 1px;
        }

        .chat-header {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            background-color: #fc8ca2 !important;
        }

        .chat-background {
            background-color: #ECE5DD !important;

            .chip-chat {
                background-color: #DCF8C6 !important;
                border-radius: 28px;

                &__content {
                    cursor: default;
                    font-size: 14px;
                    height: 32px;
                    justify-content: space-between;
                    padding: 0 12px;
                    white-space: nowrap;
                    z-index: 1;
                }
            }
        }
    }

    input {
        width: 100%;
        padding: 0 12px;
        &:focus {
            outline: none;
        }
    }

</style>