<template>
    <div class="position-relative background" v-if="bgImage" :style="'background-image: url(' + bgImage + ')'">
        <div class="container">
            <div class="py-5 row justify-content-center justify-content-lg-start">
                <h1 class="page-title">{{ title }}</h1>
            </div>
        </div>
        <div class="overlay"></div>
    </div>
    <div class="background" v-else>
        <div class="container">
            <div class="py-5 row justify-content-center justify-content-lg-start">
                <h1 class="page-title">{{ title }}</h1>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'page-cover',
    data() {
        return {
            only: ['textColor', 'bgColor']
        }
    },
    props:{
        title: {
            type: String,
            default: 'Page Title'
        },
        textColor:{
            type: String,
            default: '#000'
        },
        bgColor: {
            type: String,
            default: 'transparent'
        },
        bgImage: {
            type: String,
            default: null
        }
    },
    watch: {
        'textColor': {
            handler: function(val, oldVal) {
                Object.entries(this.$props).forEach(([key, element]) => {
                if (this.only.includes(key))
                    return document.documentElement.style.setProperty(`--${key}`, element)
                });
            },
            immediate: true
        }
    },
}
</script>
<style>
    .background {
        background-color: var(--bgColor);
        background-size: cover;
    }

    .page-title {
        color: var(--textColor);
        z-index: 1;
    }
</style>